import {
  AppBar,
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useTheme } from "../../theme/Theme";
import { FcMenu } from "react-icons/fc";
import { CiDark, CiLight, CiBellOn } from "react-icons/ci";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../hooks/useAuthStore";

import { BiChevronDown } from "react-icons/bi";
import useDashboard from "../../hooks/useDashboard";
import tecApi from "../../api/tecApi";
export const Navbar = ({ toggleSidebar }) => {
  const [expandedSections, setExpandedSections] = useState({});
  const [expandedSubSections, setExpandedSubSections] = useState({});
  const { user, startLogout } = useAuthStore();
  const companyUser = user?.company || null;
  const { data } = useDashboard(companyUser);
  const currentHour = moment().hour();
  const { mode, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const open = Boolean(anchorEl);
  const handleOpenNotifications = () => setOpenNotifications(true);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleCloseNotifications = () => setOpenNotifications(false);

  useEffect(() => {
    if (user.id) {
      // Solo hacer la petición si userId ya está disponible
      const fetchUser = async () => {
        try {
          const response = await tecApi.get(`/auth/userById/${user.id}`);
          setInfoUser(response.data.user);
        } catch (error) {
          console.error("Error al obtener el usuario:", error);
        }
      };

      fetchUser();
    }
  }, [user.id]);
  let greeting = "BIENVENIDO";
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "¡BUENOS DIAS!";
  } else if (currentHour >= 12 && currentHour < 19) {
    greeting = "¡BUENAS TARDES!";
  } else {
    greeting = "¡BUENAS NOCHES!";
  }
  const toggleCollapse = (id) => {
    setExpandedSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleSubCollapse = (parentId, companyId) => {
    setExpandedSubSections((prev) => ({
      ...prev,
      [`${parentId}-${companyId}`]: !prev[`${parentId}-${companyId}`],
    }));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: mode === "light" ? "#ffffff" : "#1f272e",
          color: mode === "light" ? "#000" : "#fff",
          boxShadow:
            mode === "light"
              ? "0px 4px 6px rgba(0, 0, 0, 0.1)"
              : "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ display: { md: "none" }, mr: 2 }}
            onClick={toggleSidebar}
          >
            <FcMenu />
          </IconButton>
          <Typography variant="body2" fontWeight="bold">
            {greeting}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <IconButton aria-label="theme" onClick={toggleTheme}>
              {mode === "light" ? <CiLight /> : <CiDark />}
            </IconButton>
            {user.department !== "Departamento Cliente" && (
              <IconButton
                aria-label="Notifications"
                onClick={handleOpenNotifications}
              >
                <CiBellOn />
              </IconButton>
            )}
            <Avatar
              src={infoUser?.imagenUser || ""}
              alt={infoUser?.name || "Usuario"}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {infoUser?.name || "User"}
              </Typography>
              <Typography variant="body2">
                {infoUser?.rol || "Tecnogenia"}
              </Typography>
            </Box>
            <IconButton aria-label="delete" onClick={handleMenuOpen}>
              <BiChevronDown />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* <MenuItem>Perfil</MenuItem>
              <MenuItem>Configuración</MenuItem> */}
              <MenuItem onClick={startLogout}>Cerrar sesión</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={openNotifications}
        onClose={handleCloseNotifications}
      >
        <Box
          sx={{ width: 350, padding: 2 }}
          role="presentation"
          onKeyDown={handleCloseNotifications}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            Notificaciones - Pendiente de cobro
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {(data.accounts || []).map((item) => (
            <Box key={item._id} sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => toggleCollapse(item._id)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                    {item._id}
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "0.875rem", fontWeight: "500" }}>
                  {item.total}
                </Typography>
              </Box>
              <Collapse in={expandedSections[item._id]}>
                <List component="div" disablePadding>
                  {(item?.companies || []).map((company) => (
                    <Box key={company.companyInfo}>
                      <ListItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: 3,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          toggleSubCollapse(item._id, company.companyInfo)
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flex: "1 1 auto",
                          }}
                        >
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              backgroundColor: "green",
                              borderRadius: "50%",
                              marginRight: 1,
                            }}
                          />
                          <ListItemText
                            primaryTypographyProps={{ fontSize: "0.775rem" }}
                            primary={company.companyInfo}
                            sx={{ margin: 0 }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            ml: 2,
                            fontSize: "0.775rem",
                          }}
                        >
                          {company.count}
                        </Typography>
                      </ListItem>

                      <Collapse
                        in={
                          expandedSubSections[
                            `${item._id}-${company.companyInfo}`
                          ]
                        }
                        sx={{ paddingLeft: 4 }}
                      >
                        <List component="div" disablePadding>
                          {company.departments?.map((detail) => (
                            <ListItem
                              key={detail.department}
                              sx={{
                                paddingLeft: 3,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  fontSize: "0.725rem",
                                }}
                                primary={detail.department}
                                sx={{ margin: 0 }}
                              />
                              <Typography
                                sx={{
                                  whiteSpace: "nowrap",
                                  ml: 2,
                                  fontSize: "0.725rem",
                                }}
                              >
                                {detail.count}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </Box>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </Box>
      </Drawer>
    </Box>
  );
};
