import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";
const CustomDatagrid = (props) => {
  return (
    <DataGrid
      {...props}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "primary.main",
          fontSize: "1rem",
          fontWeight: "bold",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "action.hover",
        },
        "& .delay-green": {
          backgroundColor: "#D4EDDA",
          color: "#155724",
          textAlign: "center",
        },
        "& .delay-yellow": {
          backgroundColor: "#FFF3CD", // Amarillo claro
          color: "#856404",
          textAlign: "center",
        },
        "& .delay-red": {
          backgroundColor: "#F8D7DA",
          color: "#721C24",
          textAlign: "center",
        },
      }}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
    />
  );
};

export default CustomDatagrid;
