import { es } from "moment/locale/es";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
moment.locale("esES", es);
const DatePickerComponent = ({ label, value, onChange }) => (
  <LocalizationProvider
    dateAdapter={AdapterMoment}
    adapterLocale="es"
    dateLibInstance={moment}
  >
    <DemoContainer components={["DatePicker"]}>
      <DatePicker
        label={label}
        ampm={false}
        value={moment(value, "YYYY-MM-DD")}
        onChange={(newValue) => onChange(newValue.format("YYYY-MM-DD"))}
        maxDate={moment()}
        slotProps={{ textField: { size: "small" } }}
        sx={{ width: "100%" }}
      />
    </DemoContainer>
  </LocalizationProvider>
);

export default DatePickerComponent;
