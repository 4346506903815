import { useAuthStore } from "../../hooks/useAuthStore";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }) => {
  const { user } = useAuthStore();

  if (!user) return <Navigate to="/login" />;
  if (!allowedRoles.includes(user.rol)) return <Navigate to="/unauthorized" />;
  return <Outlet />;
};

export default ProtectedRoute;
