import { Grid, IconButton, Avatar, Button } from "@mui/material";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import PrintReporTotal from "./PrintReporTotal";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import tecApi from "../../../../api/tecApi";
import BackButton from "../../../../resources/BackButton";
import mainLogo from "../../../../resources/imagen/excel.png"; // Imagen de Excel
import pdfLogo from "../../../../resources/imagen/pdf_4726010.png";
import moment from "moment"; // Asegúrate de importar moment

const PdfReportTotal = () => {
  // Inicializar startDate con el primer día del mes anterior
  const [values, setValues] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
  );

  // Función para obtener el primer día del mes actual usando moment
  const getFirstDayOfMonth = () => {
    return moment().startOf("month").format("YYYY-MM-DD"); // Formato YYYY-MM-DD
  };

  useEffect(() => {
    if (!startDate) return;

    const fetchReportData = async () => {
      try {
        const response = await tecApi.get("/reportTotal/report", {
          params: { startDate: startDate },
        });
        setValues(response.data.report);
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    fetchReportData();
  }, [startDate]);

  // Manejar exportación a Excel
  const handleExportExcel = () => {
    const dataForExcel = [];

    values.forEach((value) => {
      value.departments.forEach((elem) => {
        dataForExcel.push({
          "Entidad Financiera": value.company,
          Departamento: elem.department,
          "Cantidad de Boletas": elem.cantidadBoletas,
          "Total Mantenimientos": elem.totalPendiente,
          "Total Repuestos": elem.totalRepuestos,
          "Total Sicotec": elem.totalGeneral,
          "Total Factura": elem.totalAmount,
          "Total General SICOTEC": value.totalGeneralCompany,
          "Total General Factura": value.totalGeneralAmount,
          "Número Factura": elem.invoiceNumber,
          "Fecha Factura": elem.invoiceDate,
        });
      });
    });

    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const columnWidths = Object.keys(dataForExcel[0]).map((key) => ({
      wch: Math.max(
        10,
        ...dataForExcel.map((row) => row[key]?.toString().length || 0)
      ),
    }));

    ws["!cols"] = columnWidths;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte Total");
    XLSX.writeFile(wb, "Reporte_Total.xlsx");
  };

  // Manejar exportación a PDF
  const handleDownloadPDF = async () => {
    const blob = await pdf(<PrintReporTotal values={values} />).toBlob();
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Reporte_Total.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const DocumentPrint = () => <PrintReporTotal values={values} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <BackButton />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setStartDate(getFirstDayOfMonth())}
            >
              Generar Reporte del Mes Actual
            </Button>
          </Grid>

          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <IconButton color="primary" onClick={handleExportExcel}>
                  <Avatar
                    src={mainLogo}
                    alt="Excel"
                    sx={{ width: 32, height: 32 }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="secondary" onClick={handleDownloadPDF}>
                  <Avatar
                    src={pdfLogo}
                    alt="pdf"
                    sx={{ width: 30, height: 30 }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <PDFViewer
          key={values.length}
          style={{ width: "100%", height: "87vh" }}
        >
          <DocumentPrint />
        </PDFViewer>
      </Grid>
    </Grid>
  );
};

export default PdfReportTotal;
