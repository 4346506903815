import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/authSlice";
import { userSlice } from "./tec/usuarioSlice";
import { uiSlice } from "./ui/uiSlice";
import { companySlice } from "./tec/companySlice";
import { agencySlice } from "./tec/agencySlice";
import { equipmentSlice } from "./tec/equipmentSlice";
import { contactsSlice } from "./tec/contactSlice";
import { providerSlice } from "./tec/providerSlice";
import { ticketSlice } from "./tec/ticketSlice";
import { totalTicketSlice } from "./tec/totalTicketSlice";
import { productEntrySlice } from "./tec/productEntrySlice";
import { productListSlice } from "./tec/productListSlice";
import { priceSlice } from "./tec/priceSlice";
import { ticketRequestSlice } from "./tec/ticketRequestSlice";
import { staffSlice } from "./tec/staffSlice";
import { ticketClientSlice } from "./tec/ticketClientSlice";
import facturaReducer from "./tec/fileSlice";
export const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    company: companySlice.reducer,
    agency: agencySlice.reducer,
    contact: contactsSlice.reducer,
    equipment: equipmentSlice.reducer,
    provider: providerSlice.reducer,
    ticket: ticketSlice.reducer,
    totalTicket: totalTicketSlice.reducer,
    productEntry: productEntrySlice.reducer,
    productList: productListSlice.reducer,
    price: priceSlice.reducer,
    ticketRequest: ticketRequestSlice.reducer,
    staff: staffSlice.reducer,
    ticketClient: ticketClientSlice.reducer,
    facturas: facturaReducer,
  },
});
