import { useState } from "react";
import { Sidebar } from "../components/Sidebar";
import Content from "../components/content";
import { Navbar } from "../components/Navbar";
import { Box, Grid } from "@mui/material";

export const TecRoutes = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Grid container spacing={0} sx={{ height: "100vh", overflow: "hidden" }}>
      <Grid item xl={2} lg={2.5} md={3}>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </Grid>
      <Grid item xl={10} lg={9.5} md={9} sm={12} xs={12}>
        <Navbar toggleSidebar={toggleSidebar} />
        <Box
          sx={{
            flex: 1,
            padding: "20px",
            overflowY: "auto",
            maxHeight: "calc(100vh - 64px)",
            boxSizing: "border-box",
          }}
        >
          <Content />
        </Box>
      </Grid>
    </Grid>
  );
};
