import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import Headers from "../../../components/Headers";
import CustomDatagrid from "../../../../resources/CustomDatagrid";
import { useState, useEffect } from "react";
import ModalFile from "./ModalFile";

import useFacturas from "../../../../hooks/useFacturas";
import { useListCompanies } from "../../../../hooks/useListCompanies";
import { Link } from "react-router-dom";

const ReportFile = () => {
  const listCompanies = useListCompanies();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { files, handleUpdate } = useFacturas(selectedCompany?.id || null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "company",
      headerName: "Cliente",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "department",
      headerName: "Departamento",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "dateFrom",
      headerName: "Fecha Inicio",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "dateUntil",
      headerName: "Fecha Final",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "totalPendiente",
      headerName: "Total mantenimientos",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "totalRepuestos",
      headerName: "Total repuestos",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "totalGeneral",
      headerName: "Total general",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "invoiceDetailsNumber",
      headerName: "Numero de factura",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "invoiceDetailsDate",
      headerName: "Fecha de factura",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "invoiceDetailsDetails",
      headerName: "Detalle deposito",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => (
        <Button
          onClick={() => handleOpenEditModal(params.row)}
          variant="outlined"
        >
          Editar
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setRows(
      (files || []).map((value) => ({
        id: value._id,
        companyId: value.company?._id,
        company: value.company?.name,
        department: value.department,
        dateFrom: value.dateFrom,
        dateUntil: value.dateUntil,
        totalPendiente: value.totalPendiente,
        totalRepuestos: value.totalRepuestos,
        totalGeneral: value.totalGeneral,
        invoiceDetailsNumber: value?.invoiceDetails?.invoiceNumber,
        invoiceDetailsDate: value?.invoiceDetails?.invoiceDate,
        invoiceDetailsDetails: value?.invoiceDetails?.paymentMethod,
      }))
    );
  }, [files]);
  const handleOpenEditModal = (file) => {
    setSelectedFile(file);
    setOpenModal(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Headers title="INGRESOS" />
        </Grid>
        <Grid item xs>
          <Autocomplete
            size="small"
            options={listCompanies}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => setSelectedCompany(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Entidad Financiera" />
            )}
          />
        </Grid>
        <Grid item xs>
          <Link to="/reportIngresos">
            <Button variant="outlined" fullWidth>
              Reporte Ingresos
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Box className="containerDataGrid">
            <CustomDatagrid rows={rows} columns={columns} />
          </Box>
        </Grid>
      </Grid>
      <ModalFile
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={handleUpdate}
        fileData={selectedFile}
      />
    </>
  );
};

export default ReportFile;
