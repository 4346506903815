import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BackButton = ({ theme, label = "Regresar" }) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={() => {
        navigate(-1);
      }}
    >
      {label}
    </Button>
  );
};

export default BackButton;
