import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../styles/stylesPdf";
import HeaderReporPrint from "../../components/HeaderReporPrint";

const ReportKitForCompany = ({ values }) => {
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <HeaderReporPrint />
        <View style={{ marginTop: 5 }}>
          <Text
            style={{
              fontSize: "11px",
              fontFamily: "hevitas",
            }}
          >
            KIT DE LIMPIEZA
          </Text>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableHeader}>
            <Text style={{ ...styles.tableHeaderText, width: "30%" }}>
              Agencia
            </Text>
            <Text style={{ ...styles.tableHeaderText, width: "20%" }}>
              Departamento
            </Text>
            <Text style={{ ...styles.tableHeaderText, width: "20%" }}>
              Fecha
            </Text>
            <Text style={{ ...styles.tableHeaderText, width: "30%" }}>
              Detalle
            </Text>
          </View>
          {(values || []).map((value, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={{ ...styles.tableCell, width: "30%" }}>
                {value.nameAgency}
              </Text>
              <Text style={{ ...styles.tableCell, width: "20%" }}>
                {value.department}
              </Text>
              <Text style={{ ...styles.tableCell, width: "20%" }}>
                {value.emergencyKitHistory.checkedAt}
              </Text>
              <Text style={{ ...styles.tableCell, width: "30%" }}>
                {value.emergencyKitHistory.hasEmergencyKit === false
                  ? "No cuenta con el kit de limpieza"
                  : "Si cuenta con el kit de limpieza "}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default ReportKitForCompany;
