import { TextField, Button, Grid, Box, Modal } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomTitleModal from "../../../../resources/CustomTitleModal";

const ModalFile = ({ open, onClose, fileData, onSave }) => {
  const [formData, setFormData] = useState({
    company: "", // ID
    companyName: "", // Nombre visible
    department: "",
    dateFrom: "",
    dateUntil: "",
    invoiceDetails: {
      invoiceNumber: "",
      invoiceDate: moment().format("YYYY-MM-DD"), // Fecha actual por defecto
      totalAmount: "",
      paymentMethod: "",
    },
  });

  useEffect(() => {
    if (fileData) {
      setFormData({
        company: fileData.companyId || "",
        companyName: fileData.company || "", // Mostrar nombre
        department: fileData.department || "",
        dateFrom: fileData.dateFrom || "",
        dateUntil: fileData.dateUntil || "",
        invoiceDetails: {
          invoiceNumber: fileData.invoiceDetails?.invoiceNumber || "",
          invoiceDate:
            fileData.invoiceDetails?.invoiceDate ||
            moment().format("YYYY-MM-DD"),
          totalAmount: fileData.invoiceDetails?.totalAmount || "",
          paymentMethod: fileData.invoiceDetails?.paymentMethod || "",
        },
      });
    }
  }, [fileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      if (
        [
          "invoiceNumber",
          "invoiceDate",
          "totalAmount",
          "paymentMethod",
        ].includes(name)
      ) {
        return {
          ...prevState,
          invoiceDetails: {
            ...prevState.invoiceDetails,
            [name]: value,
          },
        };
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSave = () => {
    let dataToSend = { ...formData };

    // Eliminar `department` si está vacío
    if (!dataToSend.department) {
      delete dataToSend.department;
    }

    onSave(dataToSend);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <CustomTitleModal label="REGISTRO FACTURA" onClose={onClose} />
          <Grid item xs={6}>
            <TextField
              label="Entidad financiera"
              name="company"
              value={formData.companyName}
              onChange={handleChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Departamento"
              name="department"
              value={formData.department}
              onChange={handleChange}
              fullWidth
              size="small"
              placeholder="Opcional"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fecha Inicio"
              name="dateFrom"
              value={formData.dateFrom}
              onChange={handleChange}
              fullWidth
              size="small"
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fecha Final"
              name="dateUntil"
              value={formData.dateUntil}
              onChange={handleChange}
              fullWidth
              size="small"
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Número de factura"
              name="invoiceNumber"
              value={formData.invoiceDetails.invoiceNumber}
              onChange={handleChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fecha de factura"
              name="invoiceDate"
              value={formData.invoiceDetails.invoiceDate}
              onChange={handleChange}
              fullWidth
              size="small"
              type="date"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Monto de factura"
              name="totalAmount"
              value={formData.invoiceDetails.totalAmount}
              onChange={handleChange}
              fullWidth
              size="small"
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Detalle sobre pago"
              name="paymentMethod"
              value={formData.invoiceDetails.paymentMethod}
              onChange={handleChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Button onClick={onClose} variant="outlined" color="error">
                  Cancelar
                </Button>
              </Box>
              <Box>
                <Button onClick={handleSave} color="primary" variant="outlined">
                  Guardar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalFile;
