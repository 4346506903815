export const city_list = [
  "EL ALTO",
  "LA PAZ",
  "COCHABAMBA",
  "SANTA CRUZ",
  "ORURO",
  "POTOSI",
  "TARIJA",
  "CHUQUISACA",
  "BENI",
  "PANDO",
];
export const status_ticket = [
  "CONCLUIDO",
  "REINCIDENCIA",
  "REINC. PENDIENTE",
  "PENDIENTE",
  "SIN_CODIGO",
  "DIAGNOSTICO",
];
export const requestStatus = ["SI", "NO"];
export const groupEquipment = [
  {
    make: "ACCUBANKER",
    model: ["AB1100-MG/UV-PLUS", "AB1100", "D66"],
  },
  {
    make: "AERS",
    model: ["CCM-818F"],
  },
  {
    make: "AUTOMATIK",
    model: ["DB-310"],
  },
  {
    make: "BILL COUNTER",
    model: ["2089 UV/MG", "GBC-102 UV/MG", "HK-200-UV/MG"],
  },
  {
    make: "BILLCON",
    model: ["N-120", "N-120A"],
  },
  {
    make: "CDM",
    model: ["CDM-409 A"],
  },
  {
    make: "FOCUSBANKER",
    model: ["FB-50X"],
  },
  {
    make: "GLORY",
    model: [
      "GFS-220-NC",
      "GFS-220-FC",
      "GFB-801",
      "GFB-800D",
      "GFS-220-F-CS",
      "UW-F4EU",
    ],
  },
  {
    make: "KISAN",
    model: ["K5-A", "NEWTON Mini", "NEWTON3-F"],
  },
  {
    make: "MAGNER",
    model: ["30SUMI", "VC-525"],
  },
  {
    make: "MASTERWORK",
    model: [
      "NC3501-B02MI",
      "NC3501-B03MI",
      "NC6500-B03NV",
      "NC7101-B02FV",
      "NC7101-B02NV",
      "NC7101-B02OV",
      "NC7101-B03FV",
      "NC7101-B03NE",
      "NC7101-B03NV",
      "NC7101-B03OV",
      "NC7101-B04OV",
      "NC7500-B03OV",
    ],
  },
  {
    make: "TECNOMONEY",
    model: ["ST-920"],
  },
  {
    make: "VERICASH",
    model: [
      "VC115-220",
      "VC16M-220",
      "VC355",
      "VC2300",
      "VC2400-MG/UV",
      "VC2500+UV/MG",
      "VC2600-220",
      "VC3200",
      "VC3600",
      "VC3800",
      "VC4600",
      "VC4800-PRO",
    ],
  },
  {
    make: "XEROX",
    model: ["6025"],
  },
];
export const list_typeEquipment = [
  "CLASIFICADORA",
  "CONTADORA",
  "DETECTOR_DE_BILLETES",
  "REFRENDADORA",
  "TRITURADORA",
  "VALIDADORA",
  "WRITEPRINTER",
];
export const type_city = ["URBANO", "RURAL"];
export const status_user = ["ACTIVO", "INACTIVO"];

export const rolsUser = [
  "GERENCIA",
  "ADMINISTRACION",
  "OPERACIONES",
  "COORDINADOR",
  "TECNICO",
  "CLIENTE",
  "SISTEMAS",
];
export const typeUbication = ["URBANO", "RURAL"];
export const status_Equipment = ["OPERABLE", "INOPERABLE"];
export const model_Product = [
  "DB-310",
  "K5-A",
  "NC7100",
  "NC6500",
  "NC3500",
  "GFS220",
  "GFB-800D",
  "VC2600",
  "N-120",
  "UW-F4EU",
  "VC3600",
  "VC16M-220",
  "6025",
];
export const listTypeService = ["CORRECTIVO", "PREVENTIVO"];
export const listSpareStatus = [
  "NINGUNO",
  "URGENTE CAMBIO DE REPUESTO",
  "SIGUIENTE SERVICIO PARA CAMBIO",
];

export const statusTicketFinancimiento = [
  "PENDIENTE DE COBRO",
  "EN_PROCESO",
  "PAGADO",
];
export const statusRep = ["NUEVO", "REFURBISHED"];
export const listEntity = ["TECNOGENIA", "DMC", "AUTORED S.R.L.", "BMSC"];
export const listStatusClient = [
  "CONCLUIDO",
  "DIAGNOSTICO",
  "SIN_CODIGO",
  "REINCIDENCIA",
];
