import { Box, Grid, IconButton, Typography } from "@mui/material";
import { BiX } from "react-icons/bi";

const CustomTitleModal = ({ label, onClose }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ flex: 1, marginTop: 1 }}
          >
            {label}
          </Typography>
          <IconButton aria-label="delete" color="error" onClick={onClose}>
            <BiX />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

export default CustomTitleModal;
