import { Image, Text, View } from "@react-pdf/renderer";
import ImageTec from "../../resources/imagen/logoTecnogenia.png";
import { styles } from "../../styles/stylesPdf";
const InfoRow = ({ label, value }) => (
  <View style={styles.infoRow}>
    <Text style={styles.labelText}>{label}</Text>
    <Text style={styles.valueText}>: {value}</Text>
  </View>
);
const ContactSection = ({ details }) => (
  <View style={styles.contactSection}>
    {details.map((detail, index) => (
      <InfoRow key={index} label={detail.label} value={detail.value} />
    ))}
  </View>
);
const HeaderReporPrint = () => {
  const contactDetails1 = [
    { label: "Calle", value: "Av. Tomas Monje G." },
    { label: "", value: "Zona Bella Vista Nro. 22" },
    { label: "Correo", value: "tecnogenia_rl@hotmail.com" },
  ];

  const contactDetails2 = [
    { label: "Teléfono", value: "2785574" },
    { label: "Telf.Cel", value: "777266051 - 63173500" },
    { label: "NIT", value: "4890617019" },
  ];

  return (
    <View style={styles.container}>
      <ContactSection details={contactDetails1} />
      <ContactSection details={contactDetails2} />
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={ImageTec} />
      </View>
    </View>
  );
};

export default HeaderReporPrint;
