import { Font, StyleSheet } from "@react-pdf/renderer";
import fontF from "../resources/founts/ARIBL0.ttf";

Font.register({
  family: "hevitas",
  fonts: [
    {
      src: fontF,
    },
  ],
});
Font.register({
  family: "Courier",
  fonts: [
    {
      src: fontF,
    },
  ],
});
export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "0.5px solid #000", // Línea divisoria al final
    paddingBottom: 10,
    paddingTop: 10,
  },
  contactSection: {
    width: "36%",
    paddingLeft: 15,
  },
  infoRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 5,
  },
  labelText: {
    width: "30%",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333",
  },
  valueText: {
    width: "70%",
    fontSize: "12px",
    color: "#555",
  },
  logoContainer: {
    width: "28%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 15,
  },
  logo: {
    width: "100%",
    maxWidth: 120,
    objectFit: "contain",
  },
  containerTitle: {
    marginTop: "10px",
  },
  rightSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 5, // Añade separación entre elementos
  },
  titleText: {
    fontSize: 12, // Mayor tamaño para el título
    fontFamily: "hevitas",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "8px", // Espacio debajo del título
    textAlign: "left", // Alineación a la izquierda
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "hevitas",
  },
  header: {
    fontSize: 12,
    marginBottom: 17,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  styleTitle: {
    fontSize: "7px",
    textAlign: "center",
    fontFamily: "hevitas",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleData: {
    fontSize: "7px",
    textAlign: "center",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleDataName: {
    fontSize: "7px",
    textAlign: "left",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  containerHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  sectionHeader: {
    width: "36%",
    paddingHorizontal: 20,
  },
  rowHeader: {
    flexDirection: "row",
    width: "100%",
  },
  labelHeader: {
    width: "30%",
    fontSize: 12,
  },
  infoHeader: {
    width: "70%",
    fontSize: 12,
  },
  imageHeader: {
    width: 200,
    height: 33,
  },
  containerInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },
  sectionInfo: {
    width: "70%",
  },
  dateSectionInfo: {
    width: "30%",
  },
  labelInfo: {
    width: "50%",
    fontSize: 10,
  },
  valueInfo: {
    width: "50%",
    fontSize: 10,
  },
  styleDataToal: {
    fontSize: "7px",
    textAlign: "right",
    fontFamily: "hevitas",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleDataBS: {
    fontSize: "7px",
    textAlign: "right",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  titleEntry: {
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "underline",
    marginTop: "10px",
    fontFamily: "hevitas",
  },
  titleTable: {
    textAlign: "center",
    fontSize: 10,
    textTransform: "uppercase",
    fontFamily: "hevitas",
  },
  contenteTable: {
    fontSize: "9px",
    textAlign: "center",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  titleTableDetails: {
    fontSize: 12,
    textTransform: "uppercase",
    fontFamily: "hevitas",
  },
  titleTableContent: {
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
    fontFamily: "Courier",
  },

  dataContent: {
    fontSize: "10px",
    marginTop: "2px",
    textAlign: "center",
    fontFamily: "Courier",
  },
  dataContentTitle: {
    fontSize: "10px",
    textAlign: "center",
    fontFamily: "hevitas",
  },
  dataContentTableTitle: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "left",
    fontFamily: "hevitas",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableTitleRigth: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "right",
    fontFamily: "hevitas",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableleft: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "left",
    fontFamily: "Courier",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableRight: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "right",
    fontFamily: "Courier",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  //STYLES TABLE
  tableContainer: {
    width: "100%",
    marginTop: 5,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
  },
  tableHeaderText: {
    fontSize: 9,
    padding: 3,
    fontFamily: "Courier",
    textAlign: "center",
    border: "0.5px solid #000",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    padding: 3,
    fontSize: 7,
    textAlign: "center",
    border: "0.5px solid #000",
  },

  totalLabel: {
    fontSize: 7,
    padding: 3,
    fontFamily: "hevitas",
    border: "0.5px solid #000",
    textAlign: "center",
  },
  totalLabelMantenimientos: {
    fontSize: 7,
    padding: 3,
    fontFamily: "hevitas",
    border: "0.5px solid #000",
    textAlign: "center",
  },
  //PRINT TICKET
  checkReportMantenimientoFalse: {
    border: "1px solid black",
    backgroundColor: "white",
    height: "8px",
    width: "8px",

    marginTop: "1.5px",
  },
  checkReportMantenimientoTrue: {
    fontFamily: "ticket",
    fontSize: "8px",
    marginTop: "1.5px",
    border: "1px solid black",
    height: "8px",
    width: "8px",
    textAlign: "center",
  },
  titleDescriptionTicket: {
    fontSize: "8px",
    fontFamily: "hevitas",
  },
  dataDescriptionTicket: {
    fontSize: "8px",
    paddingLeft: "5px",
    fontFamily: "Courier",
  },
  labelReporteMatenimiento: {
    fontSize: "9px",
    marginLeft: "3px",
    fontFamily: "Courier",
  },
  titleSection: {
    fontSize: "10px",
    fontFamily: "hevitas",
  },
  dataSection: {
    wordBreak: "break-word",
    fontSize: 10,
    fontFamily: "Courier",
    textTransform: "uppercase",
  },
  dataTable: {
    fontFamily: "Courier",
    fontSize: "8px",
    textAlign: "center",
    border: "1px solid #000",
  },
  tableTitle: {
    fontSize: "9px",
    textAlign: "center",
    border: "1px solid #000",
  },

  //report inventory
  sectionInventory: {
    marginBottom: 15,
  },
  companyTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    borderBottom: "1px solid #000",
    paddingBottom: 3,
  },
  tableHeaderReportInventory: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    fontWeight: "bold",
    paddingBottom: 3,
  },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid #ccc",
    paddingVertical: 2,
  },
  col1: { width: "70%", fontSize: 10 },
  col2: { width: "30%", fontSize: 10, textAlign: "right" },
  containerEquipment: { width: "100%" },
  rowEquipment: {
    flexDirection: "row",
    alignItems: "center", // Alinea los textos verticalmente
    justifyContent: "space-between", // Espaciado uniforme
    width: "100%",
  },
  wideColumn: {
    width: "40%",
    flexDirection: "row",
    alignItems: "center",
  },
  mediumColumn: {
    width: "30%",
    flexDirection: "row",
    alignItems: "center",
  },
  columnEquipment: { width: "25%", flexDirection: "row", alignItems: "center" },
  dataRowEquipment: { flexDirection: "row", alignItems: "center" },
  dataTileEquipment: {
    fontSize: 10,
    fontFamily: "hevitas",
    textAlign: "left",
    fontWeight: "bold",
  },
  dataContentEquipment: { fontSize: 10, textAlign: "left", marginLeft: 5 },
});
