import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import tecApi from "../../../api/tecApi";
import { PDFViewer, Text } from "@react-pdf/renderer";
import ReportKitForCompany from "./reportKitForCompany";
import { Grid } from "@mui/material";
import BackButton from "../../../resources/BackButton";

const PrintKitCompany = () => {
  const { state } = useLocation();
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await tecApi.get("/agency/reportkitAgency", {
          params: { company: state?.company },
        });
        setCompanyData(response.data.reportKitAgency);
      } catch (error) {
        console.error("Error al obtener los datos de la empresa:", error);
      } finally {
        setLoading(false);
      }
    };

    if (state.company) {
      fetchCompanyData();
    }
  }, [state.company]);

  if (loading) {
    return <Text>Cargando datos...</Text>;
  }

  if (!companyData) {
    return <Text>Error al cargar los datos</Text>;
  }
  const DocumentPrint = () => <ReportKitForCompany values={companyData} />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BackButton />
      </Grid>
      <Grid item xs={12}>
        <PDFViewer style={{ width: "100%", height: "87vh" }}>
          <DocumentPrint />
        </PDFViewer>
      </Grid>
    </Grid>
  );
};

export default PrintKitCompany;
