import { Document, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../../../styles/stylesPdf";
import HeaderReporPrint from "../../../components/HeaderReporPrint";

const PrintReporTotal = ({ values }) => {
  // Calcular totales generales
  const totalGeneralSicotec = values.reduce(
    (acc, value) => acc + (value.totalGeneralCompany || 0),
    0
  );
  const totalGeneralFactura = values.reduce(
    (acc, value) => acc + (value.totalGeneralAmount || 0),
    0
  );

  return (
    <Document>
      <Page style={styles.body} size="A4" orientation="landscape">
        <HeaderReporPrint />
        <View style={styles.containerTitle}>
          <View style={styles.leftSection}>
            <Text style={styles.titleText}>INGRESOS</Text>
          </View>
        </View>

        <View style={{ marginTop: 5 }}>
          <View style={styles.tableContainer}>
            {/* Encabezados */}
            <View style={styles.tableHeader}>
              <Text style={{ ...styles.tableHeaderText, width: "20%" }}>
                Entidad Financiera
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "10%" }}>
                Departamento
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "6%" }}>
                Cantidad boletas
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "8%" }}>
                Mantenimientos
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "8%" }}>
                Repuestos
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "8%" }}>
                Total Sicotec
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "8%" }}>
                Total Factura
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "10%" }}>
                Total General SICOTEC
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "10%" }}>
                Total General Factura
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "6%" }}>
                Factura
              </Text>
              <Text style={{ ...styles.tableHeaderText, width: "6%" }}>
                Fecha Factura
              </Text>
            </View>

            {/* Filas dinámicas */}
            {values.map((value) => (
              <View style={styles.tableRow} key={value.company}>
                <Text style={{ ...styles.tableCell, width: "20%" }}>
                  {value.company}
                </Text>
                <View style={{ width: "10%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.department}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "6%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.cantidadBoletas}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "8%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.totalPendiente.toFixed(2)}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "8%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.totalRepuestos.toFixed(2)}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "8%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.totalGeneral.toFixed(2)}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "8%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.totalAmount.toFixed(2)}
                    </Text>
                  ))}
                </View>
                <Text style={{ ...styles.tableCell, width: "10%" }}>
                  {value.totalGeneralCompany.toFixed(2)}
                </Text>
                <Text style={{ ...styles.tableCell, width: "10%" }}>
                  {value.totalGeneralAmount.toFixed(2)}
                </Text>
                <View style={{ width: "6%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.invoiceNumber}
                    </Text>
                  ))}
                </View>
                <View style={{ width: "6%" }}>
                  {value.departments.map((elem, index) => (
                    <Text key={index} style={styles.tableCell}>
                      {elem.invoiceDate}
                    </Text>
                  ))}
                </View>
              </View>
            ))}

            {/* Fila de Totales */}
            <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
              <Text
                style={{
                  ...styles.tableCell,
                  width: "68%",
                  fontWeight: "bold",
                }}
              >
                TOTALES:
              </Text>
              <Text
                style={{
                  ...styles.tableCell,
                  width: "10%",
                  fontWeight: "bold",
                }}
              >
                {totalGeneralSicotec.toFixed(2)}
              </Text>
              <Text
                style={{
                  ...styles.tableCell,
                  width: "10%",
                  fontWeight: "bold",
                }}
              >
                {totalGeneralFactura.toFixed(2)}
              </Text>
              <Text style={{ ...styles.tableCell, width: "12%" }}></Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PrintReporTotal;
