import React, { useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../../../../../hooks/useAuthStore";
import {
  city_list,
  statusTicketFinancimiento,
} from "../../../../../resources/listData/listsData";
import tecApi from "../../../../../api/tecApi";
import moment from "moment-timezone";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import Headers from "../../../../components/Headers";
import { Link } from "react-router-dom";
import CustomDatagrid from "../../../../../resources/CustomDatagrid";
import DatePickerComponent from "../../../../../resources/DatePicker";
const firstDayPreviousMonth = moment()
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD, HH:mm:ss a");
const lastDayPreviousMonth = moment()
  .subtract(1, "month")
  .endOf("month")
  .format("YYYY-MM-DD, HH:mm:ss a");
const ListRepClientDep = () => {
  const { user } = useAuthStore();
  const [valueDateFrom, setValueDateFrom] = useState(firstDayPreviousMonth);
  const [valueDateUntil, setValueDateUntil] = useState(lastDayPreviousMonth);
  const [cityFilter, setCityFilter] = useState(
    user.city === "TODOS" ? city_list[0] : user.city
  );
  const [tickets, setTickets] = useState([]);
  const [statusTicketFilter, setStatusTicketFilter] = useState(
    statusTicketFinancimiento[0]
  );
  const callGet = () => {
    tecApi
      .get("/reportProducts/reportProducts", {
        params: {
          company: user.company,
          dateFrom: valueDateFrom,
          department: cityFilter,
          dateUntil: valueDateUntil,
          statusTicket: statusTicketFilter,
        },
      })
      .then((response) => {
        setTickets(response.data.productListCompany);
      });
  };

  const values = useMemo(() => {
    return (tickets || []).map((value) => ({
      id: value._id,
      agency: value?.ticket?.agency?.nameAgency,
      product: value?.product?.name,
      quantity: value?.quantity,
      finalSalePrice: value?.product?.finalSalePrice,
      total: value?.total,
      number: value?.ticket?.number,
      code: value?.ticket?.code,
      serie: value?.ticket?.equipment?.serie,
      model: value?.ticket?.equipment?.model,
    }));
  }, [tickets]);

  const columns = [
    {
      field: "number",
      headerName: "N°",
      width: 130,
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => `TEC-${params}`,
    },
    {
      field: "code",
      headerName: "Codigo",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "agency",
      headerName: "Agencia",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "serie",
      headerName: "Serie",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "model",
      headerName: "Modelo",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "product",
      headerName: "Nombre Repuesto",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "finalSalePrice",
      headerName: "Precio Unitario",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "total",
      headerName: "Total",
      width: 180,
      minWidth: 180,
      flex: 1,
    },
  ];

  const handleSelectCity = (event, newValue) => {
    setCityFilter(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Headers title="REPORTE DE REPUESTOS" />
          </Box>
          <Box>
            <Link
              to="/reportDepProduct"
              state={{
                company: user?.company,
                dateFrom: valueDateFrom,
                dateUntil: valueDateUntil,
                department: cityFilter,
                financialState: statusTicketFilter,
              }}
            >
              <Button variant="outlined" color="info" fullWidth>
                Reporte
              </Button>
            </Link>
          </Box>
        </Box>
      </Grid>

      {/* Conditional rendering for city selector */}
      {user.city === "TODOS" ? (
        <Grid item xs sx={{ marginTop: 1 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={city_list}
            fullWidth
            value={cityFilter}
            onChange={handleSelectCity}
            renderInput={(params) => (
              <TextField {...params} label="Ciudad" size="small" />
            )}
          />
        </Grid>
      ) : (
        <Grid item xs sx={{ marginTop: 1 }}>
          <TextField
            fullWidth
            size="small"
            value={user.city}
            label="Ciudad"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      )}

      <Grid item xs sx={{ marginTop: 1 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={statusTicketFinancimiento}
          fullWidth
          value={statusTicketFilter}
          onChange={(event, newValue) => {
            setStatusTicketFilter(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Estado Financiero" size="small" />
          )}
        />
      </Grid>
      <Grid item xs>
        <DatePickerComponent
          label="Desde"
          value={valueDateFrom}
          onChange={setValueDateFrom}
        />
      </Grid>
      <Grid item xs>
        <DatePickerComponent
          label="Hasta"
          value={valueDateUntil}
          onChange={setValueDateUntil}
        />
      </Grid>
      <Grid item xs sx={{ marginTop: 1 }}>
        <Button onClick={callGet} variant="outlined" color="info" fullWidth>
          Buscar
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box className="containerDataGrid">
          <CustomDatagrid rows={values} columns={columns} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListRepClientDep;
