import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthRoute } from "../auth/routes/AuthRoute";
import { useAuthStore } from "../hooks/useAuthStore";
import { TecRoutes } from "../tec/routes/tecRoutes";
import ThemeProviderComponent from "../theme/Theme";

export const AppRouter = () => {
  const { status, checkAuthToken } = useAuthStore();

  useEffect(() => {
    checkAuthToken();
  }, [checkAuthToken]);

  if (status === "checking") {
    return (
      <div className="contenedorLoading">
        <div className="conternedorContentLoading">
          <div className="">
            <span className="loader"></span>
          </div>
          <div className="">
            <span className="cargando">Cargando....</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ThemeProviderComponent>
      <Routes>  
        {status === "not-authenticated" ? (
          <>
            <Route path="/auth/*" element={<AuthRoute />} />
            <Route path="/*" element={<Navigate to="/auth/login" />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<TecRoutes />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </ThemeProviderComponent>
  );
};
