import { useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  FcBusinessman,
  FcElectronics,
  FcFile,
  FcHome,
  FcList,
  FcRatings,
  FcTodoList,
  FcViewDetails,
} from "react-icons/fc";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useAuthStore } from "../../hooks/useAuthStore";
import IconTec from "../../assets/icon.png";
export const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const { user } = useAuthStore();
  const [openSections, setOpenSections] = useState({});
  const location = useLocation();
  const handleClick = (section) => {
    setOpenSections((prev) => {
      if (prev[section]) {
        return {}; // Si ya está abierto, lo cierra todo
      }
      return { [section]: true }; // Abre solo la sección actual y cierra las demás
    });
  };

  const isActive = (path) => location.pathname === path;
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#282f37",
          color: "#ecf0f1",
          boxShadow: "4px 0 12px rgba(0,0,0,0.3)",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "space-between",
          overflowY: "auto",
        }}
      >
        <SidebarContent
          isActive={isActive}
          handleClick={handleClick}
          openSections={openSections}
          user={user}
        />
      </Box>
      <Drawer
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: "300px",
            backgroundColor: "#2c3e50",
            color: "#ecf0f1",
            boxSizing: "border-box",
          },
        }}
      >
        <SidebarContent
          isActive={isActive}
          handleClick={handleClick}
          openSections={openSections}
          user={user}
        />
      </Drawer>
    </>
  );
};
const SidebarContent = ({ isActive, handleClick, openSections, user }) => (
  <Box>
    <List>
      <ListItem
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          padding: "20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            padding: "8px",
          }}
        >
          <img src={IconTec} alt="Icono" style={{ width: 28, height: 28 }} />
        </ListItemIcon>
        <Box>
          <Typography
            sx={{ fontWeight: "bold", color: "#ecf0f1", letterSpacing: "1px" }}
          >
            SICOTEC
          </Typography>
          <Typography
            sx={{ color: "#bdc3c7", fontSize: "12px", fontStyle: "italic" }}
          >
            Sistema de control tecnogenia
          </Typography>
        </Box>
      </ListItem>
      <Divider sx={{ my: 2, backgroundColor: "#0074ff" }} />
      <ListItem
        component={Link}
        to="/"
        sx={{
          color: isActive("/") ? "#ff5050" : "#fff",
          backgroundColor: isActive("/") ? "#34495e" : "transparent",
          borderLeft: isActive("/") ? "4px solid #0074ff" : "none",
        }}
      >
        <FcHome />
        <ListItemText sx={{ marginLeft: 2 }} primary="Inicio" />
      </ListItem>
      {user.rol === "CLIENTE" && (
        <ListItem
          component={Link}
          to="/ticketForClient"
          sx={{
            color: isActive("/ticketForClient") ? "#ff5050" : "#fff",
            backgroundColor: isActive("/ticketForClient")
              ? "#34495e"
              : "transparent",
            borderLeft: isActive("/ticketForClient")
              ? "4px solid #0074ff"
              : "none",
          }}
        >
          <FcFile />
          <ListItemText sx={{ marginLeft: 2 }} primary="Boletas" />
        </ListItem>
      )}
      {["GERENCIA", "SISTEMAS"].includes(user.rol) && (
        <ListItem
          component={Link}
          to="/user"
          sx={{
            color: isActive("/user") ? "#ff5050" : "#fff",
            backgroundColor: isActive("/user") ? "#34495e" : "transparent",
            borderLeft: isActive("/user") ? "4px solid #0074ff" : "none",
          }}
        >
          <FcBusinessman />
          <ListItemText sx={{ marginLeft: 2 }} primary="Usuarios" />
        </ListItem>
      )}
      {["GERENCIA", "SISTEMAS", "ADMINISTRACION", "OPERACIONES"].includes(
        user.rol
      ) && (
        <>
          <ListItem
            onClick={() => handleClick("datosBoleta")}
            className="list-item"
          >
            <FcList />
            <ListItemText
              primary="Datos Boleta"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["datosBoleta"] ? <BiChevronUp /> : <BiChevronDown />}
          </ListItem>

          <Collapse
            in={openSections["datosBoleta"]}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/company", text: "Entidades Financieras" },
                { to: "/agency", text: "Agencias" },
                { to: "/contact", text: "Contactos" },
                { to: "/equipment", text: "Equipos" },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.to}
                  className={`list-option ${isActive(item.to) ? "active" : ""}`}
                >
                  <ListItemText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    disableTypography
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}

      {user.rol !== "CLIENTE" && (
        <>
          <ListItem
            onClick={() => handleClick("boletas")}
            className="list-item"
          >
            <FcViewDetails />
            <ListItemText
              primary="Boletas"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["boletas"] ? <BiChevronUp /> : <BiChevronDown />}
          </ListItem>
          <Collapse in={openSections["boletas"]} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/ticketTraining", text: "Por capacitación" },
                { to: "/ticketsForCompany", text: "Por Entidades Financieras" },
                { to: "/tickets", text: "En general" },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.to}
                  className={`list-option ${isActive(item.to) ? "active" : ""}`}
                >
                  <ListItemText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    disableTypography
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}
      {["GERENCIA", "SISTEMAS", "ADMINISTRACION", "OPERACIONES"].includes(
        user.rol
      ) && (
        <>
          <ListItem
            onClick={() => handleClick("inventario")}
            className="list-item"
          >
            <FcElectronics />
            <ListItemText
              primary="Inventario"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["inventario"] ? <BiChevronUp /> : <BiChevronDown />}
          </ListItem>
          <Collapse
            in={openSections["inventario"]}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/assetList", text: "Lista de Activos" },
                { to: "/inventory", text: "Inventario" },
                { to: "/productsEntry", text: "Entrada de repuestos" },
                { to: "/outputProduct", text: "Salida de repuestos" },
                { to: "/stockProduct", text: "Stock de repuestos" },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.to}
                  className={`list-option ${isActive(item.to) ? "active" : ""}`}
                >
                  <ListItemText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    disableTypography
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}
      {["GERENCIA", "SISTEMAS", "ADMINISTRACION", "OPERACIONES"].includes(
        user.rol
      ) && (
        <>
          <ListItem
            onClick={() => handleClick("reportBoletas")}
            className="list-item"
          >
            <FcRatings />
            <ListItemText
              primary="Reporte de boletas"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["reportBoletas"] ? (
              <BiChevronUp />
            ) : (
              <BiChevronDown />
            )}
          </ListItem>
          <Collapse
            in={openSections["reportBoletas"]}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/productxticket", text: "Cotizacion por Boleta" },
                { to: "/depReportTickets", text: "Cotización Técnica" },
                { to: "/reportFile", text: "Ingresos" },
              ]
                .filter(
                  (item) =>
                    !(user.rol === "OPERACIONES" && item.to === "/reportFile")
                ) // Filtra la opción "Ingresos" para OPERACIONES
                .map((item, index) => (
                  <ListItem
                    key={index}
                    component={Link}
                    to={item.to}
                    className={`list-option ${
                      isActive(item.to) ? "active" : ""
                    }`}
                  >
                    <ListItemText
                      sx={{ fontSize: "14px", fontWeight: "500" }}
                      disableTypography
                      primary={item.text}
                    />
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </>
      )}

      {["GERENCIA", "SISTEMAS", "ADMINISTRACION", "OPERACIONES"].includes(
        user.rol
      ) && (
        <>
          <ListItem
            onClick={() => handleClick("reportProduct")}
            className="list-item"
          >
            <FcRatings />
            <ListItemText
              primary="Reporte de repuestos"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["reportProduct"] ? (
              <BiChevronUp />
            ) : (
              <BiChevronDown />
            )}
          </ListItem>
          <Collapse
            in={openSections["reportProduct"]}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/infoEquipment", text: "Datos historico equipo" },
                { to: "/reportproductPrices", text: "Precios Repuesto" },
                { to: "/depReportProduct", text: "Reporte de Repuesto" },
                // { to: "/generalReportProduct", text: "Repuesto en general" },
                { to: "/totalProduct", text: "Precios de repuestos" },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.to}
                  className={`list-option ${isActive(item.to) ? "active" : ""}`}
                >
                  <ListItemText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    disableTypography
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}
      {user.rol === "CLIENTE" && (
        <>
          <ListItem
            component={Link}
            to="/listTicketClient"
            sx={{
              color: isActive("/listTicketClient") ? "#ff5050" : "#fff",
              pl: 2,
              backgroundColor: isActive("/listTicketClient")
                ? "#34495e"
                : "transparent",
              borderLeft: isActive("/listTicketClient")
                ? "4px solid #0074ff"
                : "none",
            }}
          >
            <FcTodoList />
            <ListItemText sx={{ marginLeft: 2 }} primary="Reporte de boletas" />
          </ListItem>
          <ListItem
            component={Link}
            to="/listProductClient"
            sx={{
              color: isActive("/listProductClient") ? "#ff5050" : "#fff",
              pl: 2,
              backgroundColor: isActive("/listProductClient")
                ? "#34495e"
                : "transparent",
              borderLeft: isActive("/listProductClient")
                ? "4px solid #0074ff"
                : "none",
            }}
          >
            <FcTodoList />
            <ListItemText
              sx={{ marginLeft: 2 }}
              primary="Reporte de repuestos"
            />
          </ListItem>
        </>
      )}
      {user.rol === "COORDINADOR" && (
        <>
          <ListItem
            onClick={() => handleClick("reportC")}
            className="list-item"
          >
            <FcRatings />
            <ListItemText
              primary="Reporte"
              sx={{ ml: 2, fontWeight: "bold" }}
            />
            {openSections["reportC"] ? <BiChevronUp /> : <BiChevronDown />}
          </ListItem>
          <Collapse in={openSections["reportC"]} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{ pl: 3, position: "relative" }}
            >
              <Box className="vertical-line" />
              {[
                { to: "/infoEquipment", text: "Datos historico equipo" },
                { to: "/totalProduct", text: "Repuestos" },
                { to: "/depReportProduct", text: "Repuesto por departamento" },
                { to: "/generalReportProduct", text: "Repuesto en general" },
                { to: "/totalProduct", text: "Precios de repuestos" },
              ].map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.to}
                  className={`list-option ${isActive(item.to) ? "active" : ""}`}
                >
                  <ListItemText
                    sx={{ fontSize: "14px", fontWeight: "500" }}
                    disableTypography
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      )}
    </List>
  </Box>
);
