import { createSlice } from "@reduxjs/toolkit";

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState: {
    isLoadingEquipment: true,
    rowsEquipment: { equipment: [], activeRows: null },
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddNewRow: (state, { payload }) => {
      state.rowsEquipment.equipment.push(payload);
      state.rowsEquipment.activeRows = null;
    },
    onUpdateRow: (state, { payload }) => {
      state.rowsEquipment.equipment = state.rowsEquipment.equipment.map(
        (event) => {
          if (event.id === payload.id) {
            return payload;
          }
          return event;
        }
      );
    },
    onDeleteRow: (state) => {
      if (state.activeRows) {
        state.rowsEquipment.equipment = state.rowsEquipment.equipment.filter(
          (event) => event.id !== state.activeRows.id
        );
        state.rowsEquipment.activeRows = null;
      }
    },
    onLoadEquipment: (state, { payload = [] }) => {
      state.isLoadingEquipment = false;
      state.rowsEquipment = payload;
    },
  },
});
export const {
  onSetActiveRows,
  onAddNewRow,
  onUpdateRow,
  onDeleteRow,
  onLoadEquipment,
} = equipmentSlice.actions;
