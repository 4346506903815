import React, { useEffect, useState } from "react";
import tecApi from "../../api/tecApi";
import { Button, Grid, Typography } from "@mui/material";
import { SkeletonTheme } from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";

const CompanyCard = React.memo(() => {
  const location = useLocation();
  const [listCompany, setListCompany] = useState([]);

  useEffect(() => {
    tecApi.get("/company/listOfCompaniesImg").then((response) => {
      setListCompany(response.data.listOfCompany);
    });
  }, []);

  const getLink = () => {
    switch (location.pathname) {
      case "/depReportTickets":
        return "/detailsDepReport";
      case "/depReportProduct":
        return "/detailsDepReportProduct";
      case "/countProducts":
        return "/detailsCountProducts";
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {listCompany.map((value, index) => (
          <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
            <div
              className="mt-3 justify-content-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <SkeletonTheme baseColor="#222e3c">
                <div className="cardStaff">
                  <div className="posterStaff">
                    <img loading="lazy" src={value.img} alt={value.name} />
                  </div>
                  <div className="details">
                    <Typography variant="h6" color="tomato" fontWeight="bold">
                      {value.name}
                    </Typography>
                    <div className="d-flex flex-column">
                      <div className="d-flex">
                        <Link
                          to={{
                            pathname: getLink(),
                          }}
                          state={{
                            company: value?.id,
                            companyName: value?.name,
                            companyImage: value?.img,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="error"
                            size="medium"
                          >
                            Ver Reporte
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SkeletonTheme>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
});

export default CompanyCard;
