import { useEffect, useState } from "react";
import tecApi from "../api/tecApi";

export const useListCompanies = () => {
  const [listCompanies, setListCompanies] = useState([]);
  useEffect(() => {
    tecApi
      .get("/company")
      .then((response) => setListCompanies(response.data.company));
  }, []);
  return listCompanies;
};
