import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tecApi from "../../api/tecApi";

// Thunks para las llamadas a la API
export const fetchFacturas = createAsyncThunk(
  "facturas/fetchFacturas",
  async (companyId) => {
    const response = await tecApi.get("/reportTotal", {
      params: { company: companyId },
    });
    return response?.data?.listReporEnProceso;
  }
);

export const updateFactura = createAsyncThunk(
  "facturas/updateFactura",
  async (updatedFile) => {
    await tecApi.put("/reportTotal/update-status-paid", updatedFile);
    return updatedFile;
  }
);

export const deleteFactura = createAsyncThunk(
  "facturas/deleteFactura",
  async (fileId) => {
    await tecApi.delete(`/factura/${fileId}`);
    return fileId;
  }
);

const facturaSlice = createSlice({
  name: "facturas",
  initialState: {
    files: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacturas.fulfilled, (state, action) => {
        state.files = action.payload;
      })
      .addCase(updateFactura.fulfilled, (state, action) => {
        const index = state.files.findIndex(
          (file) => file._id === action.payload.id
        );
        if (index !== -1) {
          state.files[index] = {
            ...state.files[index],
            ...action.payload,
            invoiceFile: action.payload.file
              ? action.payload.preview
              : state.files[index].invoiceFile,
          };
        }
      })
      .addCase(deleteFactura.fulfilled, (state, action) => {
        state.files = state.files.filter((file) => file._id !== action.payload);
      });
  },
});

export default facturaSlice.reducer;
