import { Box, Typography } from "@mui/material";
const Headers = ({ title }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box className={"content-title"}>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default Headers;
