import { useEffect, useState } from "react";
import tecApi from "../api/tecApi";

const useDashboard = (companyUser) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await tecApi.get("/dashboard", {
          params: companyUser ? { company: companyUser } : "",
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [companyUser]);

  return { data, loading, error };
};

export default useDashboard;
