import { createSlice } from "@reduxjs/toolkit";
export const contactsSlice = createSlice({
  name: "contact",
  initialState: {
    isLoadingContact: true,
    listContact: { contact: [], activeRows: null },
  },
  reducers: {
    onSetActiveRowsContact: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddRowContact: (state, { payload }) => {
      state.listContact.contact.push(payload);
      state.listContact.activeRows = null;
    },
    onUpdateRowContact: (state, { payload }) => {
      state.listContact.contact = state.listContact.contact.map(
        (event) => {
          if (event.id === payload.id) {
            return payload;
          }
          return event;
        }
      );
    },
    onDeleteRowContact: (state) => {
      if (state.activeRows) {
        state.listContact.contact = state.listContact.contact.filter(
          (event) => event.id !== state.activeRows.id
        );
        state.listContact.activeRows = null;
      }
    },
    onLoadContact: (state, { payload = [] }) => {
      state.isLoadingContact = false;
      state.listContact = payload;
    },
  },
});
export const {
  onSetActiveRowsContact,
  onAddRowContact,
  onUpdateRowContact,
  onDeleteRowContact,
  onLoadContact,
} = contactsSlice.actions;
