import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFactura,
  fetchFacturas,
  updateFactura,
} from "../store/tec/fileSlice";

const useFacturas = (companyId) => {
  const dispatch = useDispatch();
  const { files, status, error } = useSelector((state) => state.facturas);
  useEffect(() => {
    if (status === "idle" || companyId) {
      dispatch(fetchFacturas(companyId));
    }
  }, [status, dispatch, companyId]);
  const handleUpdate = async (file) => {
    await dispatch(updateFactura(file));
    dispatch(fetchFacturas(companyId));
  };
  const handleDelete = (fileId) => dispatch(deleteFactura(fileId));
  return {
    files,
    status,
    error,
    handleUpdate,
    handleDelete,
  };
};

export default useFacturas;
