import { createSlice } from "@reduxjs/toolkit";
export const productEntrySlice = createSlice({
  name: "productEntry",
  initialState: {
    isLoadingProductEntry: true,
    rowsProductEntry: [],
    activeRows: null,
  },
  reducers: {
    onSetActiveRowsProductEntry: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddNewRowProductEntry: (state, { payload }) => {
      state.rowsProductEntry.push(payload);
      state.activeRows = null;
    },
    onUpdateRowProductEntry: (state, { payload }) => {
      state.rowsProductEntry = state.rowsProductEntry.map((value) => {
        if (value.id === payload.id) {
          return payload;
        }
        return value;
      });
    },
    onDeleteRowProductEntry: (state, action) => {
      state.rowsProductEntry = state.rowsProductEntry.filter(
        (row) => row.id !== action.payload
      );
    },
    onLoadProductEntry: (state, { payload = [] }) => {
      state.isLoadingProductEntry = false;
      state.rowsProductEntry = payload;
    },
  },
});
export const {
  onSetActiveRowsProductEntry,
  onAddNewRowProductEntry,
  onUpdateRowProductEntry,
  onDeleteRowProductEntry,
  onLoadProductEntry,
} = productEntrySlice.actions;
