import { useDispatch, useSelector } from "react-redux";
import tecApi from "../api/tecApi";
import {
  clearErrorMessage,
  onLogin,
  onLogout,
} from "../store/auth/authSlice";
import { useCallback } from "react";

export const useAuthStore = () => {
  const { status, user, errorMessage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const startLogin = useCallback(
    async ({ emailOrPhone, password }) => {
      try {
        const { data } = await tecApi.post("/auth", { emailOrPhone, password });
        localStorage.setItem("token", data.token);
        dispatch(onLogin(data));
      } catch (error) {
        dispatch(
          onLogout({
            msg: error?.response?.data?.msg || "Error desconocido",
          })
        );
        setTimeout(() => {
          dispatch(clearErrorMessage());
        }, 3000);
      }
    },
    [dispatch]
  );
  const checkAuthToken = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) return dispatch(onLogout());
    try {
      const { data } = await tecApi.get("/auth/renew");
      localStorage.setItem("token", data.token);
      dispatch(onLogin(data));
    } catch (error) {
      localStorage.clear();
      dispatch(onLogout());
    }
  }, [dispatch]);
  const startLogout = useCallback(() => {
    localStorage.clear();
    dispatch(onLogout());
  }, [dispatch]);

  return {
    status,
    user,
    errorMessage,
    startLogin,
    checkAuthToken,
    startLogout,
  };
};
