import { Box, CircularProgress } from "@mui/material";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ListRepClientDep from "../pages/reports/reportClientTicket/listRep/ListRepClientDep";
import ListCardCompanies from "../pages/reports/reportProducts/depReportProduct/ListCardCompanies";
import ReportFile from "../pages/reports/reportFile/ReportFile";
import ProtectedRoute from "./ProtectedRoute";
import Unauthorized from "../pages/Unauthorized";
import PrintKitCompany from "../pages/company/printKitCompany";
import PdfReportTotal from "../pages/reports/reportFile/PdfReportTotal";
// Modulo principales
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const User = lazy(() => import("../pages/user/user"));
const Company = lazy(() => import("../pages/company/company"));
const Agency = lazy(() => import("../pages/agency/agency"));
const Contact = lazy(() => import("../pages/contact/Contact"));

//Tickets
const Tickets = lazy(() => import("../pages/ticket/tickets/Tickets"));
const TicketsForCompany = lazy(() =>
  import("../pages/ticket/ticketsForCompany/TicketsForCompany")
);
const TicketForm = lazy(() => import("../pages/ticket/ticketForm/TicketForm"));
const GenerateTicket = lazy(() =>
  import("../pages/ticket/ticketForm/GenerateTicket")
);
const TicketsClient = lazy(() =>
  import("../pages/ticket/ticketsClients/TicketsClient")
);
const TicketTraining = lazy(() =>
  import("../pages/ticket/ticketTraining/TicketTraining")
);
const PrintTraining = lazy(() =>
  import("../pages/ticket/ticketTraining/PrintTraining")
);

//Reports

const DetailsDepReport = lazy(() =>
  import("../pages/reports/reportTickets/depReport/DetailsDepReport")
);
// const GenerateDepReport = lazy(() =>
//   import("../pages/reports/reportTickets/depReport/GenerateDepReport")
// );
const ProductxTicket = lazy(() =>
  import("../pages/reports/reportTickets/productsxTicket/ProductxTicket")
);
const ReportProductForTicket = lazy(() =>
  import(
    "../pages/reports/reportTickets/productsxTicket/ReportProductForTicket"
  )
);
const StatisticsAnswer = lazy(() =>
  import("../pages/reports/reportStatistics/StatisticsAnswer")
);

//Inventario
const AssetList = lazy(() => import("../pages/inventory/assetList/AssetList"));
const Inventory = lazy(() => import("../pages/inventory/inventory/Inventory"));
const ProductsEntry = lazy(() =>
  import("../pages/inventory/productEntry/productsEntry")
);
const ProductEntryPrint = lazy(() =>
  import("../pages/inventory/productEntry/productEntryPrint")
);
const ProductOutput = lazy(() =>
  import("../pages/inventory/productOutput/productOutput")
);
const StockProducts = lazy(() =>
  import("../pages/inventory/stockProduct/stockProducts")
);
const ReportListStockPdf = lazy(() =>
  import("../pages/inventory/stockProduct/reportListStockPdf")
);
//Empresa y equipos
const Equipment = lazy(() => import("../pages/equipment/equipment"));
const EquipmentPricesModal = lazy(() =>
  import("../pages/company/equipmentPricesModal")
);
const Provider = lazy(() => import("../pages/provider/Provider"));

//Reportes de Productos
const InfoEquipment = lazy(() =>
  import("../pages/reports/reportProducts/infoEquipment/infoEquipment")
);

const CountProducts = lazy(() =>
  import("../pages/reports/reportProducts/countProducts/CountProducts")
);
const ProductPrices = lazy(() =>
  import("../pages/reports/reportProducts/ProductPrices/productPrices")
);
const TotalProduct = lazy(() =>
  import("../pages/reports/reportProducts/totalProduct/TotalProduct")
);

const DetailsDepReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/depReportProduct/DetailsDepReportProduct"
  )
);

const GenerateDepReportProduct = lazy(() =>
  import(
    "../pages/reports/reportProducts/depReportProduct/GenerateDepReportProduct"
  )
);

const ListClientTicket = lazy(() =>
  import("../pages/reports/reportClientTicket/listTicket/listClientTicket")
);

const content = () => {
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      }
    >
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          element={<ProtectedRoute allowedRoles={["GERENCIA", "SISTEMAS"]} />}
        >
          <Route path="/user" element={<User />} />
        </Route>

        <Route path="/company" element={<Company />} />
        <Route path="/company/price/:id" element={<EquipmentPricesModal />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/agency/reportKit" element={<PrintKitCompany />} />
        <Route path="/equipment" element={<Equipment />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/provider" element={<Provider />} />
        <Route path="/ticketsForCompany" element={<TicketsForCompany />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/create/ticket" element={<TicketForm />} />
        <Route path="/ticket/edit/:id" element={<TicketForm />} />
        <Route path="/ticketPrint/:id" element={<GenerateTicket />} />
        <Route path="/assetList" element={<AssetList />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/productsEntry" element={<ProductsEntry />} />
        <Route path="/productEntryPrint/:id" element={<ProductEntryPrint />} />
        <Route path="/outputProduct" element={<ProductOutput />} />
        <Route path="/stockProduct" element={<StockProducts />} />
        <Route path="/reportListInventory" element={<ReportListStockPdf />} />

        <Route path="/depReportTickets" element={<ListCardCompanies />} />
        <Route path="/detailsDepReport" element={<DetailsDepReport />} />
        {/* <Route path="/generateDepReport" element={<GenerateDepReport />} /> */}

        <Route path="/infoEquipment" element={<InfoEquipment />} />
        <Route path="/generalReportProduct" element={<ListCardCompanies />} />
        <Route path="/depReportProduct" element={<ListCardCompanies />} />
        <Route path="/countProducts" element={<ListCardCompanies />} />
        <Route path="/detailsCountProducts" element={<CountProducts />} />

        <Route
          path="/detailsDepReportProduct"
          element={<DetailsDepReportProduct />}
        />
        <Route
          path="/reportDepProduct"
          element={<GenerateDepReportProduct />}
        />
        <Route path="/reportproductPrices" element={<ProductPrices />} />

        <Route path="/ticketTraining" element={<TicketTraining />} />
        <Route path="/trainingPrint/:id" element={<PrintTraining />} />

        <Route path="/ticketForClient" element={<TicketsClient />} />

        <Route path="/listTicketClient" element={<ListClientTicket />} />

        <Route path="/listProductClient" element={<ListRepClientDep />} />
        <Route path="/totalProduct" element={<TotalProduct />} />

        <Route path="/productxticket" element={<ProductxTicket />} />
        <Route
          path="/reportProductForTicket/:id"
          element={<ReportProductForTicket />}
        />
        <Route path="/reportStatistics" element={<StatisticsAnswer />} />

        <Route path="/reportFile" element={<ReportFile />} />
        <Route path="/reportIngresos" element={<PdfReportTotal />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default content;
